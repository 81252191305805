<template>
  <div class="animated fadeIn">
    <div class="card col-6 p-0">
      <div class="card-body">
        <ResponseAlert ref="response" />
        <div v-if="!isConnected">
          <p>Klik button Auth untuk sinkronasi ke Accurate</p>
          <button class="btn btn-primary mb-1" @click="connectAccurate()">Auth</button>
          <p><small>* Ketika klik generate redirect ke halaman login accurate untuk login & isi kredensial</small></p>
        </div>
        <div v-else>
          <button class="btn btn-primary">Accurate Already Connected</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isConnected: false,
    }
  },
  methods: {
    connectAccurate() {
      this.$axios.post('/accurate-setting/auth')
        .then(res => {
          window.location = res.data.url
        })
        .catch(error => 
          this.$refs.response.setResponse(error.response.data, 'danger')
        )
    },
    checkStatus() {
      this.$axios.get('/accurate-setting/status')
        .then(res => {
          this.isConnected = res.data.data
        })
        .catch(error => 
          this.$refs.response.setResponse(error.response.data, 'danger')
        )
    }
  },
  mounted() {
    this.checkStatus()
  },
}
</script>